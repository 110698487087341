import React from 'react';
import './About.css';
import Header from '../../components/Header/Header';
import DetailContent from '../../components/DetailContent/DetailContent';

const About = () => {
  return (
    <div>
      <Header>
        <div className='about'>
          <img src='../images/about-logo.svg' />
          <p>About</p>
        </div>
      </Header>
      <div className='detail-about'>
        <div className='contact-content'>
          <DetailContent title='Who we are' para='Jutika.com is a marketplace for domain names. We make sure buying, selling and transferring domains goes smoothly and hassle-free.' />
        </div>
        <div className='contact-content'>
          <DetailContent title='What we do' para='No matter what kind of domain you want to buy, we make the transfer simple and safe.' />
        </div>
      </div>
    </div>
  );
};

export default About;
