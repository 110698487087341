import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import './Domain.css';


const Domain = ({ name = 'example.org', price = '199', description, url, index, element = {} }) => {
  const [show, setShow] = useState(false);
  // console.log(key);
  return (
    <div key={index}>
      <div className='domain-list'>
        <div className='domain'>
          <div className='domain-name' onClick={() => setShow(!show)}>{name}</div>
          <div className='domain-price'>
            <div className='price-btn'>
              <p>${price}</p>
            </div>
            <Button
              width='15rem'
              height='2.5rem'
              color='#678398'
              cursor='pointer'
              borderRadius='1rem'
              background='#FFFFFF'
              fontWeight='500'
              fontSize='1.4rem'
              lineHeight='1.7rem'
              link={url}
              type='link' />
          </div>
        </div>
        <hr />
      </div>
      {show &&
        <div className='accordion-description'>
          <div className='accordion-text'>{description}</div>
        </div>}
    </div>
  );
};

export default Domain;
