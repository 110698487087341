import React, { useEffect, useState } from 'react';
import './Domains.css';
// import { useLocation } from 'react-router-dom';
import Domain from '../../components/Domain/Domain';
// import Paginate from '../../components/Paginate/Paginate';
// import DropdownButton from '../../components/DropdownButton/DropdownButton';
import Searchbar from '../../components/Searchbar/Searchbar';
import Header from '../../components/Header/Header';
import { fetchList, fetchSearch } from '../../Api/Api'

const Domains = () => {
  // const location = useLocation();
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  // const [loaded, setLoaded] = useState(25);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState('')

  const searchCall = () => {
    fetchSearch(search)
      .then(response => {

        setList(response.data.results)
        setCount(response.data.count)
        // count = response.data.count
        // console.log(response.data.count);
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  const apiCall = () => {
    fetchList(256, pageNo)
      .then(response => {

        setList(response.data.results)
        setCount(response.data.count)
        // count = response.data.count
        // console.log(response.data.count);
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  useEffect(() => {
    apiCall()
  }, [pageNo])

  console.log(list)
  return (
    <div>
      <Header>
        <div className='searchbar'>
          <Searchbar search={(data) => {
            setSearch(data)
          }}
            event={() => {
              searchCall()
            }}
          />
        </div>
      </Header>
      {/* <div className='drop-content'><DropdownButton title = 'Sort'/></div> */}
      {list.length === 0 ? (
        <div className='no-result'>
          <img src='../images/no-result.svg' />
          <div className='result-text'>Sorry!!!  No Result Found...</div>
        </div>
      )
        :
        list.map((item) => {
          return <Domain name={item.domain_name} price={item.price} description={item.description} url={item.url} key={item.id} element={item} />
        })}
      {/* <div className='paginate-domains'>
        <Paginate
          prev = {() =>{
            if(loaded > 25){
              setPageNo(pageNo - 1)
              setLoaded(loaded - 25)
            }
          }}
          next = {() =>{
            if(loaded < count){
              setPageNo(pageNo + 1)
              setLoaded(loaded + 25)
            }
            console.log(count);
          }}

          pageNo = {pageNo}
          totalPage = {Math.ceil(count/25)}
        />
      </div> */}
      <div className="footer-padding"></div>
    </div>
  );
};

export default Domains;
