import React from 'react';
import './Contact.css'
import DetailContent from '../../components/DetailContent/DetailContent';
import Header from '../../components/Header/Header';

const Contact = () => {
  return (
    <div>
      <Header>
        <div className='contact'>
          <div className='contact-logo-content'>
            <img src='../images/contact-logo.svg' />
            <p>Contact</p>
            <img src='../images/contact-logo.svg' />
          </div>
        </div>
      </Header>
      <div className='contact-content'>
        <div className='contact-details'>
          <h3>Contact us about anything</h3>
          <p>For any queries please feel free to contact at <a target='_blank' href='https://www.tagwithdotme@gmail.com'>tagwithdotme@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
