import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <div className='footer'>
        <div className='foot-content'>
          <div className='foot-links'>
            <ul className='foot-items'>
              <li className='foot-item'><Link to='domains'>domain</Link></li>
              <li className='foot-item'><Link to='about'>about</Link></li>
              <li className='foot-item'><Link to='contact'>contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;