import React from 'react';
import Base from '../../components/Base/Base';
import { Outlet } from 'react-router-dom';

const Common = () => {
  return (
      <Base>
       <Outlet/>
      </Base>

  );
};

export default Common;
