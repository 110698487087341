import React, { useState, useEffect } from "react";
import "../../App.css";
import "./Homepage.css";
import Domain from "../../components/Domain/Domain";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Content from "../../components/Content/Content";
import { useNavigate } from "react-router-dom";
import { fetchList, fetchSearch } from "../../Api/Api";
// import Paginate from '../../components/Paginate/Paginate';
import Searchbar from "../../components/Searchbar/Searchbar";

const Homepage = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  // const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  // const [loaded, setLoaded] = useState(256);

  const searchCall = () => {
    fetchSearch(search)
      .then((response) => {
        setList(response.data.results);
        // setCount(response.data.count)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const apiCall = () => {
    fetchList(256, pageNo)
      .then((response) => {
        setList(response.data.results);
        // setCount(response.data.count)
        // count = response.data.count
        // console.log(response.data.count);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    apiCall();
  }, [pageNo]);

  console.log(list);
  console.log(search);

  return (
    <div>
      <Header>
        <div className="header">
          <div className="header-content">
            <img src="../images/header-search.svg" />
            <p>showcase yourself in the Digital World</p>
            <img src="../images/header-right.svg" />
          </div>
        </div>
      </Header>
      <div className="search-content">
        <Searchbar
          search={(data) => {
            setSearch(data);
          }}
          event={() => {
            searchCall();
          }}
        />
      </div>
      {list.length === 0 ? (
        <div className="no-result">
          <img src="../images/no-result.svg" />
          <div className="result-text">Sorry!!! No Result Found...</div>
        </div>
      ) : (
        list.map((item) => {
          return (
            <Domain
              name={item.domain_name}
              price={item.price}
              description={item.description}
              url={item.url}
              key={item.id}
              element={item}
            />
          );
        })
      )}
      {/* <div className='paginate-content'>
        <Paginate prev={() => {
          if (loaded > 25) {
            setPageNo(pageNo - 1)
            setLoaded(loaded - 25)
          }
        }}
          next={() => {
            if (loaded < count) {
              setPageNo(pageNo + 1)
              setLoaded(loaded + 25)
            }
            console.log(count);
          }}

          pageNo={pageNo}
          totalPage={Math.ceil(count / 25)}

        />
      </div> */}
      <div className="content">
        <Content />
      </div>
      <div className="btn-content">
        <Button
          text="Contact Us"
          width="19.6rem"
          height="4rem"
          fontWeight="600"
          fontSize="1.6rem"
          event={() => {
            navigate("/contact");
          }}
        />
      </div>
    </div>
  );
};

export default Homepage;
