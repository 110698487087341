import React from 'react';
import './ErrorPage.css'

function ErrorPage() {
  return (
    <div>
      <div className='error'>
        <div className='big-para'>404</div>
        <div className='para'>
          <p>ERROR! PAGR NOT FOUND</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
