import React from 'react';
import './Content.css';

const Content = () => {
  return (
    <div>
      <section className="content-contain">
        <div className="buy-transfer">
          <h3>buy and transfer domains</h3>
          <p>Jutika.com is a marketplace for domain names. We make sure buying, selling and transferring domains goes smoothly and hassle-free.</p>
          <p>Checkout seamlessly and securely with our DAN.com Escrow integration.</p>
        </div>
        <div className="payment">
          <h4>What payment methods are offered?</h4>
          <p>Transactions are currently processed through our partnership with DAN.com. DAN accepts most major payment methods including Bitcoin.</p>
          <h4>What are the fees?</h4>
          <p>Jutika.com charges 0% . Our partner DAN.com charges approximately 5% for their domain Escrow service which is applied to the buyers price during checkout.</p>
        </div>
        <div className="deal">
          <h3>Want to make a deal?</h3>
          <p>We have category-killer domains and entire domain categories. we have business domain names, automotive, Main Street, medical, education; tips, portfolio etc.</p>
        </div>
      </section>
    </div>
  );
};
export default Content;
