import React from 'react';
import './Menu.css'
import { Link } from 'react-router-dom';


function Menu(props) {

  return (
    <>
      <div className='menu'>
        <div className='menu-back'>
          <div className='menu-text'>Back</div>
          <div className='menu-icon'>
            <img src='./images/cross.svg' onClick={props.hideMenu} />
          </div>
        </div>
        <hr />
        <ul className='menu-items'>
          <div className='menu-item'>
            <img src='./images/Home.svg' />
            <li><Link to=''>Home</Link></li>
          </div>
          <div className='menu-item'>
            <img src='./images/domain.svg' />
            <li><Link to='/domains'>Domain</Link></li>
          </div>
          <div className='menu-item'>
            <img src='./images/contact.svg' />
            <li><Link to='/contact'>Contact Us</Link></li>
          </div>
          <div className='menu-item'>
            <img src='./images/About.svg' />
            <li><Link to='/about'>About Us</Link></li>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Menu;