import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from '../screen/About/About';
import Homepage from '../screen/Homepage/Homepage';
import Contact from '../screen/Contact/Contact';
import Domains from '../screen/Domains/Domains';
import Common from '../screen/Common/Common';
import ErrorPage from '../components/ErrorPage/ErrorPage';

const Routings = () => {
  return (
    <Routes>
      <Route path='/' element={<Common />}>
        <Route path='' element={<Homepage />} />
        <Route path='about' element={<About />} />
        <Route path='domains' element={<Domains />} />
        <Route path='contact' element={<Contact />} />
      </Route>
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
};

export default Routings;