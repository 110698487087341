import React from 'react';
import './Searchbar.css';

const Searchbar = ({ search = () => { }, event = () => { } }) => {
  return (
    <>
      <div className='search'>
        <div className='search-box'>
          <input
            type='text'
            className='searchText'
            placeholder=' Search your space in the digital world'
            onChange={(e) => {
              search(e.target.value)
            }}
          />

          <button
            className='search-btn'
            type='submit'
            onClick={() => {
              event()
            }}
          >
            <img src='./images/search.svg' />
          </button>
        </div>
      </div>
    </>
  );
};

export default Searchbar;
