import React from 'react';

const Button = ({
  width = '9.1rem',
  height = '2.5rem',
  color = '#5BBAAB',
  cursor = 'pointer',
  borderRadius = '1rem',
  borderColor = '#5BBAAB',
  textDecoration = 'none',
  background = '#FFFFFF',
  fontWeight = '500',
  fontSize = '1.4rem',
  lineHeight = '1.7rem',
  display = 'block',
  text = 'Buy',
  type = 'btn',
  link = '',

  event = () => {
    console.log('Hallo');
  },
}) => {
  if (type === 'link') {

    return (
      <button
        className='btn'
        style={{
          width: width,
          height: height,
          color: color,
          cursor: cursor,
          // outline: outline,
          background: background,
          borderColor: borderColor,
          borderRadius: borderRadius,
          fontWeight: fontWeight,
          fontSize: fontSize,
          lineHeight: lineHeight,
          textDecoration: textDecoration,
          text: text,
          // display: display
        }}
      >
        <a
          href={link}
          target="_blank"
          style={{
            color: color,
            textDecoration: textDecoration,
            display: display
          }}>
          {text}
        </a>
      </button>
    )

  }
  else {
    return (
      <button
        type='submit'
        className='btn'
        style={{
          width: width,
          height: height,
          color: color,
          cursor: cursor,
          // outline: outline,
          background: background,
          borderRadius: borderRadius,
          fontWeight: fontWeight,
          fontSize: fontSize,
          lineHeight: lineHeight,
          borderColor: borderColor,
          text: text
        }}
        onClick={event}
      >
        <p>{text}</p>
      </button>
    );
  }

};

export default Button;