import React from 'react';
import './DetailContent.css'

const DetailContent = (props) => {
  return (
    <div>
      <section className="domain-content">
        <div className='domain-details'>
            <h3>{props.title}</h3>
            <p>{props.para}</p>
        </div>
      </section>
    </div>
  );
};

export default DetailContent;
