// import { API } from "../backend";
import axios from "axios";

const API = 'https://app.jutika.com/api/v1/'

export const fetchList = async (page_size, page) => {
  const config = {
    method: "get",
    url: `${API}listing/?page_size=${page_size}&page=${page}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const fetchSearch = async (search_fields) => {
  const config = {
    method: "get",
    url: `${API}listing/?search=${search_fields}&search_fields=name&search_fields=domain_name`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};
