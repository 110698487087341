import React, { useState } from 'react';
import './Navbar.css'
import Menu from '../Modal/Menu/Menu'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [show, setShow] = useState(false);
  const hideMenu = () => {
    setShow(!show);
  };
  return (
    <div className='nav'>
      <div className='navbar'>
        <div className='logo-section'>
          <div className='logo-name'><Link to='/'>Jutika.com</Link></div>
          {/* <div className='logo'><Link to='/'><img src='../images/dot-me-logo.svg' /></Link></div> */}
        </div>
        <div className='hamburger-menu'>
          <label htmlFor='toggle'>&#9776;</label>
          <input type='checkbox' id='toggle' onClick={() => setShow(!show)} />
          {show && <Menu hideMenu={hideMenu} />}
        </div>

        <ul className='nav-items'>
          <li className='nav-item'><Link to='domains'>domain</Link></li>
          <li className='nav-item'><Link to='about'>about</Link></li>
          <li className='nav-item'><Link to='contact'>contact</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
